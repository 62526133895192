import React, { useRef } from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/components/Scaffold";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactInfo from "../components/ContactInfo";
import Carousel from "@client/components/Carousel";

export default (props: templates.DipTube) => {
  const scrollContainerRef = useRef(null);

  const dipTubeContentImg = [
    "/assets/products/DipTubeContent.svg",
    "/assets/products/DipTubeContent1.svg",
    "/assets/products/DipTubeContent2.svg",
    "/assets/products/DipTubeContent3.svg",
  ];
  return (
    <Scaffold title="Dip Tube">
      <Navbar scrollContainerRef={scrollContainerRef} />

      <div
        ref={scrollContainerRef}
        className="h-screen overflow-y-scroll sm:snap-y sm:snap-mandatory pt-0 sm:pt-20 scroll-smooth"
      >
        <section className="snap-start">
          <div className="relative">
            <div className="hidden sm:block">
              <div className="relative w-full  h-screen">
                <img
                  src={"/assets/products/DipTube.webp"}
                  alt="background"
                  className="w-full h-full object-cover relative z-[-100]"
                />
                <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent z-[-1]"></div>
                <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
              </div>

              <div className="flex flex-col justify-start item-start absolute top-[16%] sm:top-[40%] left-[4%] sm:left-[10%] w-[80%]">
                <h1 className="font-heading text-white font-normal text-2xl sm:text-4xl md:text-6xl mb-0 sm:mb-5">
                  Dip Tube
                </h1>
                <p className="font-body text-sm sm:text-xl text-white sm:hidden md:block mb-0 sm:mb-10">
                  Products / Dip Tube
                </p>

                <p className="font-body text-sm md:text-md lg:text-xl text-white hidden sm:block">
                  HIPEROY offers high-performance dip tubes designed for
                  durability and effective material transfer in cement
                  processing. Built to withstand tough conditions, our dip tubes
                  ensure steady, efficient cement flow and minimal wear. With
                  HIPEROY’s dip tubes, you’ll experience improved productivity
                  and optimized cement manufacturing performance.
                </p>
              </div>
            </div>
            <div className="block sm:hidden">
              <div className="relative w-full h-full">
                <img
                  src={"/assets/products/DipTubeMob.png"}
                  alt="background"
                  className="w-full h-full object-cover relative z-[-100]"
                />
                <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
              </div>

              {/* Text content positioned over the image */}
              <div className="absolute inset-0 flex flex-col justify-center items-center text-white mx-10">
                <h1 className="font-heading font-normal text-2xl">Dip Tube</h1>
                <p className="font-body text-sm mt-5 text-center">
                  Dip tubes ensure efficient and reliable material transfer in
                  cement processing, designed to withstand harsh conditions and
                  minimize wear.{" "}
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="snap-start">
          <div className="mx-12 sm:mx-10 md:mx-16 lg:px-32 pt-0 sm:pt-24">
            <div className="flex flex-col justify-center items-center">
              <h1 className="mb-6 sm:mb-10 text-2xl font-bold font-heading">
                Key Features
              </h1>
              <ul className="mb-6 sm:mb-20 font-body text-sm sm:text-lg text-justify leading-5 sm:leading-8">
                <li className="list-disc">
                  Hiperoy Solutions designs and produces steel components for
                  cyclone-based preheater systems used in cement production.
                </li>
                <li className="list-disc">
                  Dip tubes are built to withstand high temperatures, oxidation,
                  and thermal shock.
                </li>
                <li className="list-disc">
                  They ensure reliable performance in harsh conditions,
                  including extreme heat and mechanical stress.
                </li>
                <li className="">Steel Specifications:</li>

                <li className="list-disc">
                  Heat-resistant steel alloys for high temperature and corrosion
                  resistance.
                </li>
                <li className="list-disc">
                  Chromium (Cr) content: 20.0 - 30.0%.
                </li>
                <li className="list-disc">Nickel (Ni) content: 3.0 - 27%.</li>
              </ul>
            </div>

            <div className="flex justify-center items-center py-8 sm:py-0">
              <Carousel images={dipTubeContentImg} />
            </div>
          </div>

          <ContactInfo />
          <Footer />
        </section>
      </div>
    </Scaffold>
  );
};
