import React from "react";

const ContactInfo = () => {

    const handleGetInTouchClick = () => {
        console.log("contact us")
        window.location.href = "/#contact-section";
    };

    return(
        <div className="mb-6 sm:mt-10 flex flex-col justify-center items-center space-y-8 font-body">
            <p className="font-body text-center text-sm sm:text-lg">For more information or to discuss your requirements,</p>

            <button 
            className="bg-[#FC623D] text-white font-heading font-normal text-sm sm:text-md py-3 px-5 rounded-lg hover:drop-shadow-lg"
            onClick={handleGetInTouchClick}
            >
                Get in touch
            </button>

            <p className="text-center text-sm sm:text-lg">
                For detailed inquiries, please contact us at 
                <p className="text-[#0070E0] ml-2">sales@hiperoy.com.au.</p></p> 

            <p className="text-center text-sm sm:text-lg">or reach out to us at : +61 - 419 532 641 </p>
        </div>
    )

}

export default ContactInfo;