import React, { useRef } from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/components/Scaffold";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactInfo from "../components/ContactInfo";
import Carousel from "@client/components/Carousel";

export default (props: templates.BallLoading) => {
  const scrollContainerRef = useRef(null);
  const ballLoadingContentImg = [
    "/assets/products/BallLoadingContent.svg",
    "/assets/products/BallLoadingContent1.svg",
    "/assets/products/BallLoadingContent2.svg",
    "/assets/products/BallLoadingContent3.svg",
    "/assets/products/BallLoadingContent4.svg",
    "/assets/products/BallLoadingContent5.svg",
    "/assets/products/BallLoadingContent6.svg",
    "/assets/products/BallLoadingContent7.svg",
  ];
  return (
    <Scaffold title="Ball Loading">
      <Navbar scrollContainerRef={scrollContainerRef} />

      <div
        ref={scrollContainerRef}
        className="h-screen overflow-y-scroll sm:snap-y sm:snap-mandatory pt-0 sm:pt-20 scroll-smooth"
      >
        <section className="snap-start">
          <div className="relative">
            <div className="hidden sm:block">
              <div className="relative w-full h-screen">
                <img
                  src={"/assets/products/ballloading.webp"}
                  alt="background"
                  className="w-full h-full object-cover relative z-[-100]"
                />
                <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent z-[-1]"></div>
                <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
              </div>

              <div className="flex flex-col justify-start item-start absolute top-[16%] sm:top-[40%] left-[4%] sm:left-[10%] w-[80%]">
                <h1 className="font-heading text-white font-normal text-2xl sm:text-4xl md:text-6xl mb-0 sm:mb-5">
                  Ball Loading
                </h1>
                <p className="font-body text-sm sm:text-xl text-white sm:hidden md:block mb-0 sm:mb-10">
                  Products / Ball Loading
                </p>

                <p className="font-body text-sm md:text-md lg:text-xl text-white hidden sm:block">
                  A ball loading machine is a mobile device designed to
                  efficiently load media balls into ball mill grinders, ensuring
                  even distribution and improving milling efficiency. It
                  enhances overall performance by reducing manual effort and
                  ensuring consistent media placement. By streamlining the
                  process, it optimizes production and keeps operations running
                  smoothly.
                </p>
              </div>
            </div>

            <div className="block sm:hidden">
              <div className="relative w-full h-full">
                <img
                  src={"/assets/products/BallLoadingmob.png"}
                  alt="background"
                  className="w-full h-full object-cover relative z-[-100]"
                />
                <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
              </div>

              <div className="absolute inset-0 flex flex-col justify-center items-center text-white mx-10">
                <h1 className="font-heading font-normal text-2xl">
                  Ball Loading
                </h1>
                <p className="font-body text-sm mt-5 text-center">
                  The ball loading machine streamlines the process of loading
                  grinding media, enhancing efficiency and reducing manual
                  effort in milling operations.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="snap-start">
          <div className="mx-12 sm:mx-10 md:mx-16 lg:px-32 pt-0 sm:pt-24">
            <div className="flex flex-col">
              <h1 className="mb-6 sm:mb-10 text-2xl  font-bold font-heading text-start sm:text-center">
                Features
              </h1>
              <ul className="mb-6 sm:mb-20 font-body text-sm sm:text-lg leading-7 sm:leading-10 text-start sm:text-justify">
                <li className="list-disc">
                  The ball loading machine is mobile equipment designed to load
                  grinding media balls into ball mills used in mineral
                  processing and cement industries.It includes a chassis with
                  rubber tyres, a side wall rubber belt conveyor with cleats,
                  and a telescopic hydraulic boom for loading balls at a rate of
                  20 TPH.
                </li>
                <li className="list-disc">
                  The machine's conveyor can be positioned by an overhead crane,
                  with electric controls on the panel for the conveyor drive
                  motor and hydraulic power pack.A swiveling chute, manually
                  operated, aligns the conveyor with the ball mill charging
                  port.
                </li>
                <li className="list-disc">
                  The machine significantly reduces the time required for
                  recharging balls into the mill, increasing
                  productivity.Worn-out balls are removed and replaced with new
                  ones through multiple charging ports.
                </li>
              </ul>
            </div>

            <div className="flex justify-center w-full h-full my-10 sm:my-0">
              <Carousel images={ballLoadingContentImg} />
            </div>
          </div>

          <ContactInfo />
          <Footer />
        </section>
      </div>
    </Scaffold>
  );
};
