import React, { useState, useEffect } from "react";

const Navbar = ({ scrollContainerRef }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [activeItem, setActiveItem] = useState("");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setActiveItem(window.location.pathname);

      const handleScroll = () => {
        setScrollPosition(
          scrollContainerRef?.current?.scrollTop || window.scrollY
        );
      };

      if (scrollContainerRef && scrollContainerRef.current) {
        scrollContainerRef.current.addEventListener("scroll", handleScroll);
      }

      window.addEventListener("scroll", handleScroll);

      return () => {
        if (scrollContainerRef && scrollContainerRef.current) {
          scrollContainerRef.current.removeEventListener(
            "scroll",
            handleScroll
          );
        }
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [scrollContainerRef]);

  const handleClick = () => {
    setIsOpen(!isOpen);
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleItemClick = (path: string) => {
    setActiveItem(path);
    if (typeof window !== "undefined") {
      window.location.href = path;
    }
  };

  return (
    <nav
      className={`flex justify-between items-center w-full fixed left-1/2 translate-x-[-50%] px-6 md:px-16 lg:px-44 h-12 sm:h-20 z-50
          ${
            isMobileMenuOpen || scrollPosition > 200
              ? "bg-white shadow-lg"
              : "bg-transparent"
          } transition-colors duration-300 ease-in-out`}
    >
      <div>
        <a href={"/"}>
          {scrollPosition > 200 ? (
            <img
              src={"/assets/products/LogoBlack.svg"}
              className="w-[50%] h-[50%] sm:w-full sm:h-full "
            />
          ) : (
            <img
              src={"/assets/icons/Logo.svg"}
              className="w-[50%] h-[50%] sm:w-full sm:h-full"
            />
          )}
        </a>
      </div>

      <ul
        className={`sm:flex sm:gap-4 md:gap-10 lg:gap-20 hidden font-heading text-sm font-normal   ${
          scrollPosition > 200 ? "text-black" : "text-[#FAFAFA4D]"
        } transition-colors duration-300 ease-in-out`}
      >
        <li
          onClick={() => handleItemClick("/")}
          className={`hover:text-[#FC623D] cursor-pointer ${
            activeItem === "/" ? "text-red-500" : ""
          }`}
        >
          {" "}
          Home{" "}
        </li>

        <div className="relative group">
          <li
            onClick={() => handleItemClick("/product")}
            className={`hover:text-[#FC623D] cursor-pointer ${
              activeItem === "/product/" ? "text-red-500" : ""
            }`}
          >
            {" "}
            Products{" "}
          </li>

          {/* Submenu */}
          <ul
            className={`absolute left-0 top-full rounded-xl mt-3 p-2 px-6 transition-opacity duration-300 whitespace-nowrap
                        ${
                          scrollPosition > 200
                            ? "bg-white shadow-lg"
                            : "bg-[#000000]"
                        } opacity-0 group-hover:opacity-100`}
          >
            <li
              className={`my-3 ${
                scrollPosition > 200
                  ? "hover:text-[#FC623D] text-black"
                  : "hover:text-[#FC623D] text-white"
              }`}
            >
              <a href="/product/grinding-media/">Grinding Media</a>
            </li>
            <li
              className={`mb-3 ${
                scrollPosition > 200
                  ? "hover:text-[#FC623D] text-black"
                  : "hover:text-[#FC623D] text-white"
              }`}
            >
              <a href="/product/body-liners/">Body Liners</a>
            </li>
            <li
              className={`mb-3 ${
                scrollPosition > 200
                  ? "hover:text-[#FC623D] text-black"
                  : "hover:text-[#FC623D] text-white"
              }`}
            >
              <a href="/product/mill-liners/">Mill Liners</a>
            </li>
            <li
              className={`mb-3 ${
                scrollPosition > 200
                  ? "hover:text-[#FC623D] text-black"
                  : "hover:text-[#FC623D] text-white"
              }`}
            >
              <a href="/product/dip-tube/">Dip Tubes</a>
            </li>
            <li
              className={`mb-3 ${
                scrollPosition > 200
                  ? "hover:text-[#FC623D] text-black"
                  : "hover:text-[#FC623D] text-white"
              }`}
            >
              <a href="/product/mining-equipment/">Machinery</a>
            </li>
            <li
              className={`mb-3 ${
                scrollPosition > 200
                  ? "hover:text-[#FC623D] text-black"
                  : "hover:text-[#FC623D] text-white"
              }`}
            >
              <a href="/product/spares/">Parts</a>
            </li>
          </ul>
        </div>

        <li
          onClick={() => handleItemClick("/about")}
          className={`hover:text-[#FC623D] cursor-pointer ${
            activeItem === "/about/" ? "text-red-500" : ""
          }`}
        >
          About Us
        </li>
        <li
          onClick={() => handleItemClick("/#contact-section")}
          className={`hover:text-[#FC623D] cursor-pointer ${
            activeItem === "#contact-section" ? "text-red-500" : ""
          }`}
        >
          Contact Us
        </li>
      </ul>

      <button onClick={handleClick} className="sm:hidden">
        {isOpen ? (
          <img
            src={"/assets/products/CloseButton.svg"}
            className="w-[60%] h-[60%] sm:w-full sm:h-full"
          />
        ) : scrollPosition > 200 ? (
          <img
            src={"/assets/icons/hamBlack.svg"}
            className="w-[60%] h-[60%] sm:w-full sm:h-full"
          />
        ) : (
          <img
            src={"/assets/icons/hamB.svg"}
            className="w-[60%] h-[60%] sm:w-full sm:h-full"
          />
        )}
      </button>

      <ul
        className={`bg-white h-screen flex flex-col font-heading text-xs font-normal justify-start items-center gap-y-6  pt-10 absolute top-full shadow-xl left-0 w-full 
                sm:hidden transition-transform transform ${
                  isOpen ? "translate-y-0" : "translate-y-[-200%]"
                }`}
      >
        <li
          onClick={() => handleItemClick("/")}
          className={`hover:text-[#FC623D] text-black ${
            activeItem === "/" ? "text-red-500" : ""
          }`}
        >
          Home
        </li>
        <li
          onClick={() => handleItemClick("/product")}
          className={`hover:text-[#FC623D] text-black ${
            activeItem === "/product" ? "text-red-500" : ""
          }`}
        >
          Products
        </li>
        <li
          onClick={() => handleItemClick("/about")}
          className={`hover:text-[#FC623D] text-black ${
            activeItem === "/about" ? "text-red-500" : ""
          }`}
        >
          About Us
        </li>
        <li
          onClick={() => handleItemClick("/#contact-section")}
          className={`hover:text-[#FC623D] text-black ${
            activeItem === "#contact-section" ? "text-red-500" : ""
          }`}
        >
          Contact Us
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
