import React, { useRef } from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/components/Scaffold";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import BodyLinerBanner from "../components/BodyLinerBanner";

import ContactInfo from "../components/ContactInfo";

export default (props: templates.BodyLiner) => {
  const scrollContainerRef = useRef(null);

  return (
    <Scaffold title="Body Liner">
      <Navbar scrollContainerRef={scrollContainerRef} />

      <div
        ref={scrollContainerRef}
        className="h-screen overflow-y-scroll sm:snap-y sm:snap-mandatory pt-0 sm:pt-20 scroll-smooth"
      >
        <section className="snap-start">
          <BodyLinerBanner />
        </section>
        <section className="snap-start">
          <div className="mx-12 sm:mx-10 md:mx-16 lg:px-32 pt-0 sm:pt-24">
            <div className="flex flex-col">
              <h1 className="mb-6 sm:mb-10 text-2xl font-bold font-heading text-start sm:text-center">
                Key Features
              </h1>
              <ul className="font-body text-sm sm:text-lg leading-7 sm:leading-10 text-start sm:text-justify">
                <li className="list-disc">
                  High-Performance Alloys: Our liners are made with top-quality
                  alloys that offer exceptional resistance to erosion and
                  abrasion, standing strong in tough conditions.
                </li>
                <li className="list-disc">
                  Advanced Design: Precision-engineered to handle airflow and
                  abrasive materials, our liners minimize wear and keep your
                  mill running smoothly.
                </li>
                <li className="list-disc">
                  Enhanced Durability: Built to last, our liners extend the
                  lifespan of your equipment, reducing the need for frequent
                  replacements and cutting down on maintenance costs.
                </li>
                <li className="list-disc">
                  Reliable Performance: Designed to thrive in harsh
                  environments, our liners deliver dependable, long-term use you
                  can count on.
                </li>
              </ul>
            </div>

            <div className="flex justify-center w-full h-full">
              <img
                src={"/assets/products/BodyLinerContent.svg"}
                alt="background"
                className="w-full h-full sm:w-3/4 sm:h-3/4  object-cover mx-auto"
              />
            </div>

            <ContactInfo />
          </div>

          <Footer />
        </section>
      </div>
    </Scaffold>
  );
};
