import React, { useRef } from "react";
import { templates } from "@reactivated";
import { Scaffold } from "../components/Scaffold";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ProductBanner from "../components/ProductBanner";
import ContactInfo from "@client/components/ContactInfo";
import SectorCard from "@client/components/SectorCard";

export default (props: templates.Product) => {
  const scrollContainerRef = useRef(null);

  return (
    <Scaffold title="Product">
      <Navbar scrollContainerRef={scrollContainerRef} />

      <div
        ref={scrollContainerRef}
        className="h-screen overflow-y-scroll sm:snap-y sm:snap-mandatory pt-0 sm:pt-20 scroll-smooth"
      >

        <section className="snap-start">
          <ProductBanner />
        </section>

        <section className="snap-start">
          <div className="flex flex-col items-center space-y-8 md:space-y-14 pt-0 sm:pt-24">
            <h1 className="mb-6 sm:mb-18 text-2xl font-bold font-heading text-center">
              Catalogue
            </h1>

            <div className="flex flex-col sm:flex-row space-y-8 sm:space-y-0 space-x-0 sm:space-x-4 md:space-x-16 px-8 sm:px-0">
              <SectorCard
                title="Grinding Media"
                imageSrc="/assets/products/GrindingMediaSectorCard.svg"
                link="grinding-media/"
              />
              <SectorCard
                title="Body Liners"
                imageSrc="/assets/products/BodyLinerSectorCard.svg"
                link="body-liners/"
              />
            </div>

            <div className="flex flex-col sm:flex-row space-y-8 sm:space-y-0 space-x-0 sm:space-x-4 md:space-x-16 px-8 sm:px-0">
              <SectorCard
                title="Mill Liners"
                imageSrc="/assets/products/MillLinerCard.svg"
                link="mill-liners/"
              />
              <SectorCard
                title="Dip Tubes"
                imageSrc="/assets/products/DipTubeCard.svg"
                link="dip-tube/"
              />
            </div>

            <div className="flex flex-col sm:flex-row space-y-8 sm:space-y-0 space-x-0 sm:space-x-4 md:space-x-16 px-8 sm:px-0">
              <SectorCard
                title="Machinery"
                imageSrc="/assets/products/MachineryCard.svg"
                link="mining-equipment/"
              />
              <SectorCard title="Parts" imageSrc="/assets/products/PartsCard.svg"  link="spares/"/>
            </div>
          </div>
        <div className="mx-12 sm:mx-10 md:mx-16 lg:px-32 my-10 sm:my-20">
        <ContactInfo />
      </div>
        </section>

        <section className="snap-start">
          <Footer />
        </section>

      </div>

    </Scaffold>
  );
};
