import React, { useRef } from "react";
import { templates } from "@reactivated";
import { Scaffold } from "@client/components/Scaffold";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactInfo from "../components/ContactInfo";
import ExpandableContent from "../components/ExpandableContent";

export default (props: templates.MobileSeries) => {
  const scrollContainerRef = useRef(null);
  return (
    <Scaffold title="Mobile Series">
      <Navbar scrollContainerRef={scrollContainerRef} />

      <div
        ref={scrollContainerRef}
        className="h-screen overflow-y-scroll sm:snap-y sm:snap-mandatory pt-0 sm:pt-20 scroll-smooth"
      >
        <section className="snap-start">
          <div className="relative">
            <div className="hidden sm:block">
              <div className="relative w-full h-screen">
                <img
                  src={"/assets/products/mobileseries.webp"}
                  alt="background"
                  className="w-full h-full object-cover relative z-[-100]"
                />
                <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent z-[-1]"></div>
                <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
              </div>

              <div className="flex flex-col justify-start item-start absolute top-[16%] sm:top-[40%] left-[4%] sm:left-[10%] w-[80%]">
                <h1 className="font-heading text-white font-normal text-2xl sm:text-4xl md:text-6xl mb-0 sm:mb-5">
                  Mobile Series
                </h1>
                <p className="font-body text-sm sm:text-xl text-white sm:hidden md:block mb-0 sm:mb-10">
                  Products / Machinery / Mobile Series
                </p>

                <p className="font-body text-sm md:text-md lg:text-xl text-white hidden sm:block">
                  HIPEROY’s Mobile Series equipment delivers unmatched
                  flexibility and efficiency. Our mobile crushers and screens,
                  including track-mounted units, are built to fit any mining
                  site. They offer quick setup and top performance, handling
                  tough conditions with ease. Designed for on-the-go operations,
                  these units ensure high productivity and reliable performance
                  wherever you need it.
                </p>
              </div>
            </div>

            <div className="block sm:hidden">
              <div className="relative w-full h-full">
                <img
                  src={"/assets/products/MobileSeriesMob.png"}
                  alt="background"
                  className="w-full h-full object-cover relative z-[-100]"
                />
                <div className="absolute inset-0 h-1/4 bg-gradient-to-b from-black to-transparent z-[-1]"></div>
              </div>

              <div className="absolute inset-0 flex flex-col justify-center items-center text-white mx-10">
                <h1 className="font-heading font-normal text-2xl">
                  Mobile Series
                </h1>
                <p className="font-body text-sm mt-5 text-center">
                  The mobile series machinery offers versatile, on-the-go
                  solutions with track-mounted equipment designed for efficient
                  material processing in any location.{" "}
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="snap-start">
          <div className="mx-9 sm:mx-10 md:mx-16 lg:px-32 pt-0 sm:pt-24">
            <div className="flex flex-col">
              <h1 className="mb-6 sm:mb-10 text-2xl font-bold font-heading text-start sm:text-center">
                Key Features
              </h1>
              <ul className="mb-6 sm:mb-20 font-body text-sm sm:text-lg leading-7 sm:leading-10 text-start sm:text-justify">
                <li className="list-disc">
                  High Productivity: Built for on-site crushing and screening,
                  our equipment delivers reliable and steady output.
                </li>
                <li className="list-disc">
                  Compact Configuration: Space-saving design maintains high
                  power and capability without taking up extra room.
                </li>
                <li className="list-disc">
                  Rapid Deployment: Fast setup between sites reduces downtime
                  and keeps operations running smoothly.
                </li>
              </ul>
            </div>

            <div className="flex flex-col justify-center items-center font-body md:px-20 lg:px-24 xl:px-64">
              <ExpandableContent
                title="Track Mounted Jaw"
                moreInfo={false}
                content="The Track-Mounted Jaw Crusher is a powerful, mobile solution for efficient on-site operations. It excels in mobility, enabling fast setup and easy relocation across diverse mining environments. Built for high performance, this unit boosts flexibility and productivity, ensuring reliable operation and effective material handling in challenging conditions."
              />
              <ExpandableContent
                title="Track Mounted Cone"
                moreInfo={false}
                content="Our Track-Mounted Cone delivers powerful secondary and tertiary stage of ore crushing with top-tier mobility. Its design ensures swift setup and easy relocation, offering dependable performance and boosting productivity across diverse mining environments."
              />
              <ExpandableContent
                title="Track Mounted Endurer"
                moreInfo={false}
                content="Our Track-Mounted Endurer is a powerhouse for mobile screening, delivering exceptional performance and reliability. With its track-mounted design, it offers superb mobility and fast setup, making it perfect for ever-changing mining sites. Built for toughness and efficiency, it boosts productivity and ensures consistently high-quality results."
              />
              <ExpandableContent
                title="Track Mounted Vertical Shaft Impactor"
                moreInfo={false}
                content="The Track-Mounted Vertical Shaft Impactor is a top-performing mobile unit for versatile impact crushing. Its track-mounted design ensures excellent mobility and fast relocation, perfect for dynamic mining sites. Built for durability and precision, it offers reliable, high-quality impact crushing and boosts productivity across different job sites."
              />
              <ExpandableContent
                title="Track Mounted Screen"
                moreInfo={false}
                content="HIPEROY’s Track-Mounted Screen is built for high-performance screening in mining. Its mobile, track-mounted design makes it easy to move and set up quickly. This flexibility allows for efficient material separation and classification right at the site. Engineered for toughness and reliability, it boosts productivity and ensures consistent, top-quality screening results in any mining environment."
              />
              <ExpandableContent
                title="Track Mounted Scalper"
                moreInfo={false}
                content="The Track-Mounted Scalper is a powerful mobile unit built for efficient primary screening and material separation. Its track-mounted design ensures easy mobility and fast setup, enabling swift processing of large material volumes. Designed for durability and top performance, it delivers reliable operation and boosts productivity in fast-paced mining environments."
              />
            </div>
          </div>

          <ContactInfo />
          <Footer />
        </section>
      </div>
    </Scaffold>
  );
};
